<template>
  <div class="email-page">
    <m-page-title
      title="Update Personal Profile"
      describe="*indicates required field"
    />
    <!-- <h3 class="item-title">Email</h3> -->
    <el-form
      :model="emailForm"
      ref="emailForm"
      :rules="rules"
      label-position="top"
      size="medium"
      class="m-top20"
      @keyup.enter.native="saveClick"
      @submit.native.prevent
    >
      <el-form-item label="New email address" prop="email">
        <div class="email">
          <el-input v-model="emailForm.email" class="form-input"></el-input>
          <el-button
            type="primary"
            class="verify-email-btn"
            :loading="verifyEmailBtn.loading"
            :disabled="Boolean(verifyEmailBtn.status)"
            @click="verifyEmail"
          >
            {{ verifyEmailBtn.label }}
          </el-button>
        </div>
      </el-form-item>
      <el-form-item label="Verification Code" prop="code">
        <el-input v-model="emailForm.code" class="form-input"></el-input>
      </el-form-item>
    </el-form>
    <div class="flex-row btn-box">
      <m-back />
      <el-button class="save-btn" type="primary" @click="saveClick">
        Save
      </el-button>
    </div>
  </div>
</template>

<script>
import validate from "@/utils/validateForm";

export default {
  name: "basicInfo",
  components: {},
  data() {
    this.rules = {
      email: [
        { required: true, message: "Email cannot be empty", trigger: "blur" },
        { validator: validate.email, trigger: "blur" },
      ],
      code: [
        {
          required: true,
          message: "Verification code cannot be empty",
          trigger: "blur",
        },
      ],
    };
    return {
      emailForm: { email: null, code: null },
      verifyEmailBtn: {
        loading: false,
        label: "Verify Email",
        status: 0, // 倒计时 为0是可操作
      },
    };
  },
  computed: {},
  methods: {
    async saveClick() {
      if (!(await this.$refs.emailForm.validate())) return false;
      this.$axios.post(this.$api.updateEmail, this.emailForm).then((res) => {
        if (res.code === "1000") this.$notify.success("Saved successfully");
      });
    },
    // 获取验证码
    verifyEmail() {
      let msg = "msg";
      this.$refs.emailForm.validateField("email", (err) => (msg = err));
      if (msg) return false;
      this.verifyEmailBtn.loading = true;
      const options = [
        this.$api.updateEmailEmail,
        { email: this.emailForm.email },
        { headers: { domainName: this.$api.domain } },
      ];
      this.$axios.post(...options).then((res) => {
        this.verifyEmailBtn.loading = false;
        if (res.code === "1000") {
          this.verifyEmailBtnCount();
          this.$notify.success("Verification code has been sent");
        }
      });
    },
    // 获取验证码按钮
    verifyEmailBtnCount(index = 60) {
      this.verifyEmailBtn.label = index ? index + "s" : "Verify Email";
      this.verifyEmailBtn.status = index;
      index--;
      index >= 0 && setTimeout(() => this.verifyEmailBtnCount(index), 1000);
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
